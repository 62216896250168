<template>
  <div class="faq">
    <div class="faq__container">
      <div class="faq__title">{{ $t('faq.header') }}</div>
      <h3
          v-for="(faq, index) in faqs"
          :class="['faq-text', { opened: opened === index }]"
          :key="index"
          @click="toggleFaq(index)"
      >
        <div class="faq-text__title">
          <p class="title">{{ faq.question }}</p>
          <img src="../assets/icons/plus.svg"/>
        </div>
        <p class="text">
          {{ faq.answer }}
        </p>
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      opened: -1
    }
  },
  methods: {
    toggleFaq(index) {
      if (this.opened === index) {
        this.opened = -1;
      } else {
        this.opened = index;
      }
    },
  },
  props: {
    faqs: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.faq {
  .faq__container {
    //padding: 0 20px;

    .faq__title {
      margin: 17px 0;
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      color: #ffffff;
    }

    .faq-text {
      margin-bottom: 24px;
      padding-bottom: 12px;
      border-bottom: 1px solid #595a69;
      overflow: hidden;
      transition: 1s;

      .faq-text__title {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .title {
          max-width: 230px;
          margin-bottom: 12px;
          font-weight: 500;
          font-size: 16px;
          line-height: 140.7%;
          color: #ffffff;
        }

        img {
          transition: 0.4s;
          transform: rotate(0);
        }
      }

      .text {
        max-height: 0;
        font-weight: 300;
        position: relative;
        font-size: 16px;
        line-height: 140.7%;
        color: #ffffffea;
        opacity: 0;
        visibility: hidden;
        transition: 0.5s;
        transform: translateY(40px);
      }

      &.opened {
        transition: 2s;

        .faq-text__title {
          img {
            transform: rotate(45deg);
          }
        }

        .text {
          max-height: 1000px;
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        }
      }
    }
  }
}
</style>
